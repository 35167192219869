import { ActionType } from 'typesafe-actions';
import { CustomerData } from '../../Modal/Labels';
import * as actions from './commonActions';


export type CommonActions = ActionType<typeof actions>;

export interface ICommonState {
  loading: boolean,
  customerData: CustomerData,
  FilterLoading: boolean
}

export enum Constants {
  SET_LOADING = 'SET_LOADING',
  SET_LOGIN = 'SET_LOGIN',
  RESET_STORE = 'RESET_STORE',
  SET_CUSTOMERDATA = 'SET_CUSTOMERDATA',
  SET_FILTERED_LOADING = "SET_FILTERED_LOADING"
}