import Aos from "aos";
import React, { lazy, Suspense, useEffect } from "react";
// import './App.css';
import "./App.less";
// import AppRouter from './Routes';
import LoadingScreen from "./components/loadingScreen";
import ScrollToTop from "./utils/ScrollToTop";

const AppRouter = lazy(() => import("./Routes"));

const App = () => {
  ScrollToTop()
  useEffect(() => {
    Aos.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <Suspense fallback={<div></div>}>
        
        <AppRouter />
      </Suspense>
    </>
  );
};

export default App;
