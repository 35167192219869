import {
  ProductsActions,
  Constants,
  IProductsState,
} from "../products/productsTypes";
import { ProductsMdl } from "../../Modal/Products";
const init: IProductsState = {
  products: [] as ProductsMdl[],
  product: [] as ProductsMdl[],
  Relproducts: [] as ProductsMdl[],
  cartProductOrder: [] as ProductsMdl[],
  AddedCartProduct: [] as any,
  CartData: [] as any,
  CartOrderData: [] as any,
  searchData: [] as any,
  previousLocation: [] as any,
  secondaryImageUrl: [] as any,
  productReleseasValue: [] as any,
  label_value: [] as any,
  RSDAvailable: false as boolean,
};


export const productsReducer = (
  state: IProductsState = init,
  action: ProductsActions
) => {
  switch (action.type) {
    case Constants.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.loadMore
          ? [...state.products, ...action.payload.products]
          : [...action.payload.products],
      };
    case Constants.SET_PRODUCT_COUNT:
      return {
        ...state,
        productCount: action.payload.count,
      };
    case Constants.SET_PRODUCT:
      return {
        ...state,
        product: action.payload.product,
      };
    case Constants.SET_REL_PRODUCTS:
      return {
        ...state,
        Relproducts: action.payload.Relproducts,
      };
      case Constants.SET_PRODUCT_RSDAVAILABLE:
        return {
          ...state,
          RSDAvailable: action.payload.RSDAvailable,
        };
    case Constants.SET_CART_PRODUCT_ORDER:
      return {
        ...state,
        CartProductOrder: action.payload.CartProductOrder,
      };

    case Constants.SET_CART_DATA:
      return {
        ...state,
        AddedCartProduct: action.payload.AddedCartProduct,
      };
    case Constants.SET_ADDED_CART_PRODUCT:
      return {
        ...state,
        CartData: action.payload.CartData,
      };
    case Constants.CART_ORDER_DATA:
      return {
        ...state,
        CartOrderData: action.payload.CartOrderData,
      };
    case Constants.SET_SEARCH_DATA:
      // return {
      //   ...state,
      //   searchData: action.payload.searchData,
      // };
      return {
        ...state,
        searchData: action.payload.loadMore
          ? [...state.searchData, ...action.payload.searchData]
          : [...action.payload.searchData],
      };
    case Constants.SET_SEARCH_COUNT:
      return {
        ...state,
        searchDataCount: action.payload.searchDataCount,
      };
    case Constants.PREVIOUS_LOCATION_URL:
      return {
        ...state,
        previousLocation: action.payload.previousLocation,
      };
    case Constants.SECONDARY_iMAGE_URL:
      return {
        ...state,
        secondaryImageUrl: action.payload.secondaryImageUrl,
      };
    case Constants.SET_PRODUCT_RELEASES_ENUM_VALUE:
      return {
        ...state,
        productReleseasValue: action.payload.productReleseasValue,
      };
    case Constants.SET_PRODUCT_RELEASES_ENUM_VALUES:
      return {
        ...state,
        releases_enums_values: action.payload.releases_enums_values,
      };
    case Constants.SET_REDIRECT_PATH_FROM_RELAESES:
      return {
        ...state,
        store_redirect_path: action.payload.store_redirect_path,
      };
    //SET_LABLE_VALUE
    case Constants.SET_LABLE_VALUE:
      return {
        ...state,
        label_value: action.payload.label_value,
      };

    default:
      return state;
  }
};
