import { createStore, applyMiddleware } from 'redux';
import { ICommonState } from './common/commonTypes';
import thunkMiddleware from 'redux-thunk';
    import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
export interface IRootState {
    common: ICommonState
}

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

const persistConfig = {
    key: 'persist-root',
    storage
}

const persistedReducer: any = persistReducer(persistConfig, rootReducer);

const store = createStore<IRootState, any, any, any>(persistedReducer, composedEnhancer);
const persistor = persistStore(store)

export { store, persistor };